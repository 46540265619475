<template>
  <div>
    <b-card-group deck>
      <b-card
          header="Tạo mới combo voucher"
          header-tag="header"
      >
        <b-form v-if="show">
          <div class="row">
            <div class="col-md-8">
              <b-form-group
                  id="input-dk-2"
                  label="Tên combo(*)"
                  label-for="ms-title"
              >
                <b-form-input
                    id="ms-title"
                    v-model="form.title"
                    maxlength="191"
                    placeholder="Tên combo"
                    :class="[{ 'is-invalid': form.errors.has('title') }]"
                ></b-form-input>
                <has-error :form="form" field="title"></has-error>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group
                  id="input-dk-2"
                  label="Kích hoạt"
                  label-for="ms-title"
              >
                <b-form-checkbox
                    id="checkbox-1"
                    v-model="form.status"
                    value="1"
                    unchecked-value="0"
                    size="lg"
                >
                </b-form-checkbox>
              </b-form-group>
            </div>
            <div class="col-md-8">
              <b-form-group label="Ảnh:" >
                <div>
                  <div style="height: 50px;" v-if="form.img">
                    <img
                        style="width: 38px; height: 38px"
                        :src="form.img"
                        alt=""
                    />
                  </div>
                  <b-form-file
                      @change="onImageChange($event, 'img')"
                      placeholder="chọn ảnh"
                      accept="image/*"
                      type="file"
                      :class="[{ 'is-invalid': form.errors.has('img') }]"
                  ></b-form-file>
                  <has-error :form="form" field="img"></has-error>
                </div>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group
                  id="input-dk-2"
                  label="Mô tả"
                  label-for="ms-desc"
              >
                <b-form-textarea
                    id="ms-desc"
                    v-model="form.desc"
                    placeholder="Nhập mô tả "
                    min="0"
                    rows="4"
                    :class="[{ 'is-invalid': form.errors.has('desc') }]"
                ></b-form-textarea>
                <has-error :form="form" field="desc"></has-error>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group
                  id="input-dk-2"
                  label="Mô tả chi tiết"
                  label-for="ms-desc-detail"
              >
                <vue-editor required v-model="form.desc_detail"></vue-editor>
                <has-error :form="form" field="desc_detail"></has-error>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <b-form-group
                  id="combo_type"
                  label="Loại(*):"
                  label-for="input-1"
              >
                <b-form-select v-model="form.type" :class="[{ 'is-invalid': form.errors.has('type') }]" :disabled="is_disable">
                  <option value="">--Chọn--</option>
                  <option value="CHALLENGE">CHALLENGE</option>
                  <option value="COMBO_VOUCHER">COMBO_VOUCHER</option>
                </b-form-select>
                <has-error :form="form" field="type"></has-error>
              </b-form-group>
            </div>
            <template v-if="form.type === 'COMBO_VOUCHER'">
              <div class="col-md-3" >
                <b-form-group
                    id="client_id"
                    label="MerchantId(*):"
                    label-for="input-1"
                >
                  <b-form-select v-model="form.client_id" :class="[{ 'is-invalid': form.errors.has('client_id') }]" :disabled="is_disable">
                    <option value="">--Chọn--</option>
                    <option v-for="(client, index) in clients" :key="index" :value="client.id">{{ client.name }}</option>
                  </b-form-select>
                  <has-error :form="form" field="client_id"></has-error>
                </b-form-group>
              </div>
              <div class="col-md-3" >
                <b-form-group
                    id="event_id"
                    label="Campaign(*):"
                    label-for="input-1"
                >
                  <b-form-select v-model="form.event_id" :class="[{ 'is-invalid': form.errors.has('event_id') }]" :disabled="is_disable">
                    <option value="">--Chọn--</option>
                    <option v-for="(client, index) in events" :key="index" :value="client.id">{{ client.name }}</option>
                  </b-form-select>
                  <has-error :form="form" field="event_id"></has-error>
                </b-form-group>
              </div>
            </template>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-12">
              <h4>Thông tin quà tặng:</h4>
            </div>
          </div>
          <div class="row" v-for="(item, index) in giftItems" :key="index">
            <template >
              <div class="col-md-3">
                <b-form-group
                    id="combo_item_type"
                    label="Loại quà tặng(*):"
                    label-for="input-1"
                >
                  <b-form-select v-model="item.type" :disabled="is_disable">
                    <option value="1">Voucher</option>
                    <option value="2">Tiền thưởng</option>
                    <option value="3">Điểm loyalty</option>
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group
                    id="combo_item_campaign_code"
                    :label="item.type == 1 ? 'ID Quà tặng:': item.type == 2 ? 'Tiền thưởng:': 'Điểm thưởng'"
                    label-for="input-1"
                >
                  <b-form-input v-if="item.type != 1 "
                      id="input-1"
                      @keydown.space.prevent
                      type="number" min="1"
                      v-model="item.value"
                      :class="[{ 'is-invalid': form.errors.has(`items.${index}.value`) }]"
                      :disabled="is_disable"
                  ></b-form-input>
                  <b-form-input v-else
                    id="input-1"
                    @keydown.space.prevent
                    type="text"
                    v-model="item.value"
                    :class="[{ 'is-invalid': form.errors.has(`items.${index}.value`) }]"
                    :disabled="is_disable"
                  ></b-form-input>
                  <has-error :form="form" :field="`items.${index}.value`"></has-error>
                  <b-btn  v-if="item.type == 1 " class="btn btn-sm btn-info" @click="checkInfoCoupon(item.value)">
                    Check
                  </b-btn>
                </b-form-group>
              </div>
              <div class="col-md-3" v-if="item.type == 1 && form.type === 'COMBO_VOUCHER'">
                <b-form-group
                    id="combo_item_campaign_code"
                    label="số lượng quà trong 1 combo(*):"
                    label-for="input-1"
                >
                  <b-form-input
                      id="input-1"
                      type="number"
                      required
                      min="1"
                      v-model="item.num_receive"
                      :class="[{ 'is-invalid': form.errors.has(`items.${index}.num_receive`) }]"
                      :disabled="is_disable"
                  ></b-form-input>
                  <has-error :form="form" :field="`items.${index}.num_receive`"></has-error>
                </b-form-group>
              </div>
              <div class="col-md-3" style="padding-top: 27px;" v-if="index > 0 && !is_disable">
                <b-button variant="danger" size="sm" @click="removeGiftItem(index)">Xóa</b-button>
              </div>
            </template>
          </div>
          <div class="row" v-show="!is_disable">
            <div class="col-md-12">
              <b-button variant="info" size="sm" @click="addGiftItem()">+Thêm quà tặng</b-button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" style="text-align: right;">
              <b-button v-if="!is_edit" type="submit" variant="primary" style="margin-right: 10px;" @click="saveForm">
                Tạo mới
              </b-button>
              <b-button v-else type="submit" variant="primary" style="margin-right: 10px;" @click="updateCombo">
                Cập nhật
              </b-button>
              <router-link v-if="is_edit" :to="'/campaign/combo'">
                <b-button variant="danger">Hủy bỏ</b-button>
              </router-link>
              <b-button v-else type="reset" variant="danger">Làm mới</b-button>
            </div>
          </div>
        </b-form>
      </b-card>
    </b-card-group>
    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import { Form } from 'vform';
import {
  HasError,
} from 'vform/src/components/bootstrap5'
import { VueEditor } from "vue2-editor";
Vue.component("multiselect", Multiselect);
Vue.component(HasError.name, HasError)
const CampaignRepository = RepositoryFactory.get("Campaign");
const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");
const WalletRunRepository = RepositoryFactory.get("WalletRun");
import AlertDialogue from "@/view/pages/components/AlertModal.vue";
export default {
  name: "FormMission",
  mixins: [Common],
  components: {
    VueEditor,AlertDialogue
  },
  data() {
    return {
      form: new Form({
        id: '',
        title: '',
        desc: '',
        type: '',
        status: 1,
        client_id: '',
        event_id: '',
        items: [],
        img: '',
        desc_detail: '',
      }),
      giftItems: [
        {
          id: '',
          type: 1,
          value: '',
          num_receive: ''
        }
      ],
      clients: [],
      events: [],
      show: true,
      is_edit: false,
      is_disable: false,
    };

  },
  computed: {
    clientID () {
      return this.form.client_id;
    }
  },
  watch: {
    clientID () {
      this.loadEvents();
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lí combo ", route: "/campaign/combo" },
      { title: "Thêm combo" },
    ]);
  },
  methods: {
    async checkInfoCoupon (code) {
      let self = this;
      let response = await CampaignRepository.getInfoCoupon(code);
      if (response.status !== 200 || response.data.error_code === 1) {
        self.$bus.$emit("show-loading", false);
        return (self.errorMessage = "Có lỗi khi lấy thông tin");
      }

      self.$bus.$emit("show-loading", false);
      const cfm = await this.$refs.confirmDialogue.show({
        title: "Thông tin Voucher",
        message:
            "<ul style='text-align: left'><li>"+
            "ID:  " +response.data.data.id+
            "</li><li>" +
            "Code: " +response.data.data.code+
            "</li><li>" +
            "Tên: " +response.data.data.name+
            "</li><li>" +
            "Số lượng: "+this.numberWithCommas(response.data.data.max_gift)+"</li></ul>",
        okButton: "Ok",
      });

      if (!cfm) {
        this.$bus.$emit("show-loading", false);
        return (self.errorMessage = "Có lỗi khi lấy thông tin");
      }

      this.$bus.$emit("show-loading", false);

    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    addGiftItem () {
      this.giftItems.push({
        id: '',
        type: 1,
        value: '',
        num_receive: ''
      });
    },
    removeGiftItem (index) {
      if (index > 0) {
        this.giftItems.splice(index, 1);
      }
    },
    saveForm () {
      this.form.items = this.giftItems;
      this.form.submit('post', '/campaign/combo/store')
          .then(({data}) => {
            if (1 === data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: data.message,
              });
            } else {
              this.$notify({
                group: "foo",
                type: "success",
                title: data.message,
              });
              this.$router.push({ name: "combo_campaign" });
            }
          })
          .catch(() => {
            this.$notify({
              group: "foo",
              type: "error",
              title: "Vui lòng kiểm tra lại form nhập liệu.",
            });
          })
    },
    async getClients() {
      let response = await WalletCampaignRepository.getClients();
      this.clients = response.data.data;
    },
    async loadEvents () {
      try {
        this.$bus.$emit("show-loading", true);
        let filter = {
          client_id: this.form.client_id,
          is_show_all: true
        }
        let response = await WalletCampaignRepository.getEvents(filter);
        this.$bus.$emit("show-loading", false);
        this.events = response.data.data;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async loadCombo (id) {
      try {
        let response = await CampaignRepository.getCombo(id);
        if (response.data.error_code === 0) {
          let data = response.data.data;
          this.form.fill(data);
          this.form.desc_detail = data.desc_detail;

          if (data.script_combo_vouchers.length > 0) {
            this.is_disable = true;
          }

          if (this.form.items.length > 0) {
            let that = this;
            this.giftItems = [];
            this.form.items.forEach(item => {
              let value = ''
              if (item.type == 1) {
                 value = item.campaign_code;
              } else if (item.type == 2) {
                 value = item.amount;
              } else {
                 value = item.point;
              }

              that.giftItems.push({
                id: item.id,
                type: item.type,
                value: value,
                num_receive: item.num_receive
              })

            })
          }
        } else {
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          group: "foo",
          type: "error",
          title: error,
        });
      }
    },
    updateCombo() {
      this.form.items = this.giftItems;
      this.form.submit('post', '/campaign/combo/update/'+this.form.id)
          .then(({data}) => {
            if (1 === data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: data.message,
              });
            } else {
              this.$notify({
                group: "foo",
                type: "success",
                title: data.message,
              });
              this.$router.push({ name: "combo_campaign" });
            }
          })
          .catch((error) => {
            this.$notify({
              group: "foo",
              type: "error",
              title: error,
            });
          })
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        title: '',
        desc: '',
        desc_detail: '',
        type: '',
        status: 0,
        client_id: '',
        event_id: '',
        items: [],
        img: ''
      };
      this.giftItems = [
        {
          type: 1,
          value: '',
          num_receive: ''
        }
      ];
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize> 2){
        this.$notify({
          group: "foo",
          type: "error",
          title:"Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia();
      //this.createImage(files[0]);
    },
    uploadMedia: async function () {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);

      WalletRunRepository.uploadMedia(formData)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              this.form.img = response.data.data.link;
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
  },
  created() {
    this.getClients();
    if (this.$route.params.id) {
      this.is_edit = true;
      this.loadCombo(this.$route.params.id)
    }

  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style >
.title-block{
  color: #FF0000;
  font-size: 18px;
  font-weight: bold;
}
.datetime-picker div input{
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3F4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
